.test {
  color: red;
  font-size: 20px;
  background-color: #ff8500;
  width: 100px;
  height: 100px;
}

.header_left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dColor {
  color: rgba(0, 0, 0, 0.45);
}

.tag_list {
  padding: 0 12px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.tag_list::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}

.tag_list::-webkit-scrollbar-thumb {
  background-color: #108ee9;
  border-radius: 6px;
}

.tag_list::-webkit-scrollbar-thumb:hover {
  background-color: #108ee9;
}

.logoVertical {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 54px;
  overflow: hidden;
  border-bottom: 1px solid #f5f5f5;
  border-right: 1px solid #f5f5f5;
}

.logoVertical img {
  height: 32px;
  width: 32px;
  border-radius: 4px;
}

.logoVertical span {
  color: #1b1b1b;
  font-size: 16px;
  font-weight: bold;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logoVertical:hover {
  cursor: pointer;
}

.breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: url("../images/data_bg.png") no-repeat;
  background-size: 100%;
  background-size: cover;
}

.login .login_title {
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  color: #707070;
  margin-bottom: 16px;
  text-align: center;
}

.login .loginForm {
  width: 360px;
}

.login .loginForm .loginInput {
  height: 38px;
}

.login .loginForm .loginFormIcon {
  margin-right: 4px;
}

.login .loginForm .verificationCode {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login .loginForm .verificationCode .code_d {
  margin-left: 12px;
  height: 38px;
  width: 182px;
  border-radius: 4px;
}

.login .loginForm .verificationCode .code_d .codeValideImg {
  height: 38px;
  width: 182px;
  border-radius: 4px;
}

.button {
  width: 100%;
  height: 38px;
}

.pagination {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.pageFooter {
  margin-top: 12px;
}

.modalForm {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.home {
  padding: 18px;
  border-bottom: 18px solid #efefef;
}

.home .home_body .home_title {
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.home .home_body .home_title span {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #a0a0a0;
}

.home .home_body .home_body_col {
  display: flex;
  flex-direction: column;
}

.home .home_body .home_body_col span {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
}

.home .home_body .home_body_col .col_price {
  margin-top: 8px;
  font-size: 18px;
  font-weight: 600;
  color: #1b1b1b;
}

.home .home_body .tabs_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.home .home_body .tabs_picker {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home .home_body .tabs_picker .span {
  margin-left: 48px;
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
}

.allocation_title {
  font-size: 14px;
  font-weight: 400;
  color: #6379bb;
  border-bottom: 1px solid #ddd;
  margin-bottom: 48px;
  padding-bottom: 5px;
}

.financeData {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 240px;
  background-color: #f8f9fa;
  padding: 15px 20px;
  margin-right: 32px;
}

.financeData span {
  font-size: 12px;
}

.financeData .financeDataPrice {
  font-size: 28px;
  margin-bottom: 5px;
  font-weight: 700;
}
