.odd-row {
  background-color: rgba(249, 249, 249, 0.4);
}

.expand-icon {
  transform: rotate(-90deg);
  transition: transform .3s ease-out;
  margin-right: 4px;
}

.expand-icon1 {
  transform: rotate(0);
  transition: transform .3s ease-out;
  margin-right: 4px;
}