/* box-sizing reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* reset margin and padding */
html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
/* em, */
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* standardize HTML5 display elements */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* standardize image border style */
img {
  border-style: none;
}

.ant-form-item-explain-error {
  font-size: 12px;
}

.ant-table-row > .ant-table-cell:empty::before {
  content: "-";
}

.ant-layout-content::-webkit-scrollbar {
  height: 2px;
  width: 0px;
  background-color: #f5f5f5;
}

.ant-layout-content::-webkit-scrollbar-thumb {
  background-color: #108ee9;
  border-radius: 2px;
}

.ant-layout-content::-webkit-scrollbar-thumb:hover {
  background-color: #108ee9;
}

.ant-form-item {
  margin-bottom: 0;
}
.ant-upload-list {
  overflow: hidden;
  /* width: 220px; */
}