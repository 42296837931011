.App {
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: #f3f4f5;
  /* text-align: center; */
}

.fade_breadcrumb-exit {
  /* transform: translateX(150px); */
  opacity: 0;
  transition: all 0.4s ease-in;
}
.fade_breadcrumb-exit-active {
  transform: translateX(150px);
  opacity: 1;
  transition: all 0.5s ease-in;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(150px);
  transition: all 0.4s ease-in;
}
.ant-table-cell {
  word-break: break-all;
}

.selectBreadcrumb {
  color: black !important;
  margin-right: 4px;
}

.ant-menu-sub > .ant-menu-item {
  /* padding-left: 30px !important; */
}
