.userManager {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.userManager .userManager_left {
  width: 20%;
  padding: 0 20px;
}

.userManager .userManager_right {
  width: 80%;
}
